const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

const initValine = () => {
  setTimeout(() => {
    if (window.Valine) {
      new window.Valine({
        el: '#vcomments',
        appId: 'pM54H0DsksTFxE6NmsbNjadD-gzGzoHsz',
        appKey: 'Gj13EcXGE1jEpDQ9hh3lNeYS',
        requiredFields: ['nick','mail'],
        placeholder: '欢迎留下您的使用反馈、功能建议或咨询问题，被回复后会收到邮件提醒！',
        avatar:'robohash',
        avatar_cdn: 'https://cravatar.cn/avatar/',
        visitor: true, // 阅读量统计
        enableQQ: true,
        path: /^\/convert\/download\//.test(window.location.pathname) ? '/convert/download' : window.location.pathname,
      })
    }
  }, 2000)
}

const imageClickHandler = () => {
  document.addEventListener('click', function(e) {
    if (e.target.tagName === 'IMG'
      && e.target.src
      && (e.target.src.includes('daozhao.com') || e.target.src.includes('fitconverter.com'))
      && e.target.closest('.app-box-body')) {
      showModal(e.target.src);
    } else {
      hideModal();
    }
  });

  function showModal(imageUrl) {
    const modal = document.getElementById("modal");
    const modalImage = document.getElementById("modal-image");
    if (modal && modalImage) {
      modal.style.display = "block";
      modalImage.src = imageUrl;
    }
  }

  function hideModal() {
    const modal = document.getElementById("modal");
    if (modal) {
      modal.style.display = "none";
    }
  }
}

const load51LaTongJi = () => {
  const options ={
    id: "K8VYEj2YzzwEOsl6",
    ck: "K8VYEj2YzzwEOsl6",
    autoTrack: true
  }

  const scriptElement = document.createElement("script");
  scriptElement.src = "//sdk.51.la/js-sdk-pro.min.js";
  scriptElement.async = true;
  scriptElement.id = "LA_COLLECT";
  
  options.d = scriptElement;
  const s = document.getElementsByTagName("script")[0];
  if (window.LA) {
    if (window.LA.ids) {
      window.LA.ids.push(options)
    }
  } else {
    window.LA = options;
    window.LA.ids = [];
    window.LA.ids.push(options)
  }
  s.parentNode.insertBefore(scriptElement, s);
} 

const loadBaiduTongJi = () => {
  var _hmt = window._hmt || [];
  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?cccd115b0d9d9ec40f6222a8f6a35a54";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}

const loadCnzzTongJi = () => {
  (function(){
    var el = document.createElement("script");
    el.src = "https://lf1-cdn-tos.bytegoofy.com/goofy/ttzz/push.js?87cfc4e188e48dc1d3fd1d18159ca224bb6224e6ad587d98aa3dfd1eb4ad1fe845f9b46c8c41e6235de98982cdddb9785e566c8c06b0b36aec55fccc04fff972a6c09517809143b97aad1198018b8352";
    el.id = "ttzz";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(el, s);
  })(window);
}

const loadClarityTongJi = () => {
  (function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "kdxc812n25");
}

const runScript = () => {
  imageClickHandler();
  initValine();
  load51LaTongJi();
  loadBaiduTongJi();
  loadCnzzTongJi();
  loadClarityTongJi();
}


export const onClientEntry = () => {
  window.onload = () => {
    addScript("//unpkg.com/valine/dist/Valine.min.js")
    addScript("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3013839362871866")
    runScript();
  }
}